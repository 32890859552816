<template>
  <v-dialog v-model="pointRankUpDialog" content-class="rankupdialog">
    <v-card class="" v-if="point_rankup">
      <v-card-title class="body-2 maincolor-bg justify-center mb-5">
        <h1 class="subtitle-1 font-weight-bold mb-3">ランクアップしました!</h1>
        <p class="subtitle-2 text-center pa-0 ma-0" style="width:100%;">あなたの現在のランク</p>
        <h2 class="title font-weight-bold text-center pa-0" style="width:100%;">{{ point_rankup.rank_str }}</h2>
      </v-card-title>
      <div class="text-center pb-5">
        <v-btn rounded class="maincolor-bg default_button btn_small" @click.stop="close()">閉じる</v-btn>
      </div>

      <div class="rankimg" v-if="point_rankup.rank_img_path">
        <img :src="point_rankup.rank_img_path" width="50">
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['point_rankup'],
  data: () => ({
    pointRankUpDialog: false,
  }),
  methods: {
    open() {
      this.pointRankUpDialog = true;
    },
    close() {
      this.pointRankUpDialog = false;
    }
  }
}
</script>

<style scope>
.rankimg {
  width: 50px;
  position: absolute;
  top: -10px;
  left: 10px;
}
.rankupdialog {
  overflow-y: visible !important;
}
</style>