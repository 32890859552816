<template>
  <v-dialog v-model="pointHistoryDialog" content-class="mx-2">
    <v-card class="text-center pa-3">

      <div class="px-3">
        <h4 class="subtitle-1 font-weight-bold">ポイント履歴</h4>
      </div>
      <div class="px-3 fontsize9">
        最後にご利用した月から3ヵ月間を表示
      </div>
      <v-divider class="my-2"></v-divider>

      <div class="mb-4 px-4" v-if="point_history && point_history.data != ''">
        <v-row no-gutters align="center" style="border-bottom: solid 1px #9D9D9D;" v-for="item in point_history.data" :key="item.id">
          <v-col cols="7" class="fontsize13">
            {{ item.create_date }}
          </v-col>
          <v-col cols="2" class="fontsize13">
            {{item.app_type_name}}
          </v-col>
          <v-col cols="3" class="text-right title font-weight-bold">
            {{ Number(item.point).toLocaleString() }} <span class="subtitle-1 font-weight-bold">p</span>
          </v-col>
        </v-row>
      </div>

      <div class="mb-4 px-4" v-else>
        <p class="body-2">ポイント履歴はありません。</p>
      </div>
      <v-divider class="my-2"></v-divider>

      <div class="text-center">
        <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="close()">閉じる</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      pointHistoryDialog: false,
    }
  },
  props: ['point_history', 'init'],
	methods: {
    open() {
      this.pointHistoryDialog = true;
    },
    close() {
      this.pointHistoryDialog = false;
    }
  },
  mounted() {
  },
}
</script>
