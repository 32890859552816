<template>
  <v-dialog v-model="qrDialog">
    <v-card class="text-center pa-3">
      <div class="pa-10 text-center pt-5 pb-5" style="background: #fff;">
        <qrcode :value="qrcodeurl" :options="{ width: 200, margin: 1 }"></qrcode>
      </div>

      <div class="px-3">
        <p class="">スタッフにご提示ください。</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['qrcodeurl'],
  data() {
    return {
      qrDialog: false,
    };
  },
  methods: {
    open() {
      this.qrDialog = true;
    },
    close() {
      this.qrDialog = false;
    }
  }
}
</script>