<template>
  <v-dialog v-model="pointAboutDialog" content-class="mx-2">
    <v-card class="text-center pa-3">
      <div class="px-3">
        <h4 class="subtitle-1 font-weight-bold">ランクについて</h4>
      </div>
      <v-container>
        <v-row v-if="point && point.hasOwnProperty('rank_detail')">
          <v-col cols="3" class="text-center m-0 px-1" align="center" v-for="rank in point.rank_detail" :key="rank.id">
            <div class="mx-2" style="height:3.5rem;">
              <v-img v-if="rank.rank_img_path" :src="rank.rank_img_path" lazy-src="@/assets/noimg@2x.png" max-width="50">
              </v-img>
              <v-img v-else src="@/assets/noimg@2x.png" max-width="50">
              </v-img>
            </div>
            <h5 class="fontsize11 font-weight-bold my-2" v-if="rank.rank_str">{{ rank.rank_str }}</h5>
            <p class="fontsize11 mb-1" style="white-space:nowrap;">{{ rank.rank_condition }}</p>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="mt-4 mb-6"></v-divider>

      <div class="px-3">
        <h4 class="subtitle-1 font-weight-bold pb-1">ポイントについて</h4>
        <p class="fontsize11" style="overflow-wrap: normal; text-align: left;">
          店舗でのお買い上げ・お支払い金額に応じて、やまやポイントが貯まります。<br>
          ランクによってポイント還元率が変わります。
        </p>
        <p>
          <v-row class="mt-0 mb-0 fontsize11">
            <v-col cols="4" class="py-0 pr-0 text-left">レギュラー</v-col><v-col class="py-0 pl-0 text-left">1％</v-col>
          </v-row>
          <v-row class="mt-0 mb-0 fontsize11">
            <v-col cols="4" class="py-0 pr-0 text-left">ブロンズ</v-col><v-col class="py-0 pl-0 text-left">2％</v-col>
          </v-row>
          <v-row class="mt-0 mb-0 fontsize11">
            <v-col cols="4" class="py-0 pr-0 text-left">シルバー</v-col><v-col class="py-0 pl-0 text-left">3％</v-col>
          </v-row>
          <v-row class="mt-0 mb-0 fontsize11">
            <v-col cols="4" class="py-0 pr-0 text-left">ゴールド</v-col><v-col class="py-0 pl-0 text-left">4％</v-col>
          </v-row>
        </p>
        <p class="fontsize11" style="overflow-wrap: normal; text-align: left;">
          貯めたポイントは店舗やオンラインショップで1ポイント1円としてご利用いただけます。<br>
          ※ポイントの有効期限は最終利用日翌日から365日です。<br>
          ※ランクは同じランクで1年経過後の月末から過去12か月をポイント集計期間とし、その期間に獲得したポイント数で計算されます。
        </p>
        <v-divider class="mt-4 mb-6"></v-divider>

        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="close()">閉じる</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['point'],
  data() {
    return {
      pointAboutDialog: false
    };
  },
  methods: {
    open() {
      this.pointAboutDialog = true;
    },
    close() {
      this.pointAboutDialog = false;
    }
  }
}
</script>
