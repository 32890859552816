<template>
  <!-- スタンプカードについてダイアログ -->
  <v-dialog v-model="stampAboutDialog" content-class="mx-2">
    <v-card class="ticket_dialogue pa-3">
      <div class="px-3">
        <h4 class="subtitle-1 font-weight-bold">対象店舗はこちら</h4>
      </div>
      <v-divider class="my-2"></v-divider>

      <v-img v-if="stamp.image_path" :src="stamp.image_path" lazy-src="@/assets/noimg@2x.png" class="mb-5">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" class="mb-5">
      </v-img>

      <v-divider class="my-2"></v-divider>
      <p class="caption mb-8" v-html="stamp.detail.replace(/\n/g,'<br/>')"></p>

      <div class="text-center">
        <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="close()">閉じる</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['stamp'],
  data() {
    return {
      stampAboutDialog: false,
    };
  },
  methods: {
    open() {
      this.stampAboutDialog = true;
    },
    close() {
      this.stampAboutDialog = false;
    }
  }
}
</script>
